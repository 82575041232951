const BASE = import.meta.env.VITE_API_ENDPOINT as string;

const routes = {
  history() {
    return `${BASE}/chat/history`;
  },

  conversation(id: string) {
    return `${BASE}/chat/conversation/${id}`;
  },

  delete(id: string) {
    return `${BASE}/chat/conversation/${id}`;
  },
} as const;

export default routes;
