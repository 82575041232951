import { useQuery } from "@tanstack/react-query";
import { forwardRef } from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import Enrollment from "@/models/enrollment";

import Unassign from "./Unassign";

const AssignedMentorTableRow = forwardRef(function AssignedMentorTableRow(
  { mentor, onSuccess, onError, unassign, inventoryItem, sections },
  ref,
) {
  const section = useQuery({
    queryKey: Enrollment.fetchLmsEnrollment.queryKey({
      partnerId: inventoryItem.partnerId,
      inventoryId: inventoryItem._id,
      email: mentor.email,
    }),
    queryFn() {
      return Enrollment.fetchLmsEnrollment.queryFn({
        partnerId: inventoryItem.partnerId,
        inventoryId: inventoryItem._id,
        email: mentor.email,
      });
    },
    retry: 3,
  });
  const IS_LOADING = sections.isLoading || section.isLoading;
  const studentCount = sections.data?.find(
    (s) => s.id === section.data?.id,
  )?.total_students;
  return (
    <TableRow ref={ref} value={mentor.email}>
      <TableCell>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {mentor.firstName} {mentor.lastName}
        </Typography>
        <Typography variant="body2" sx={{ opacity: 0.6 }}>
          {mentor.email}
        </Typography>
        <br></br>
        <Typography variant="body1">
          Students: {IS_LOADING ? "Loading..." : studentCount}
        </Typography>
        {IS_LOADING ? null : (
          <Typography variant="body1">
            In Canvas: <strong>{section.isSuccess ? "Yes" : "No"}</strong>
          </Typography>
        )}
      </TableCell>

      <TableCell align="right">
        {!IS_LOADING && (
          <Unassign
            onError={onError}
            onSuccess={onSuccess}
            unassign={unassign}
            studentCount={studentCount}
          />
        )}
      </TableCell>
    </TableRow>
  );
});

export default AssignedMentorTableRow;
