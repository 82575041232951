import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Chat from "@/models/chat";
import { CHAT_ROLE } from "@/models/chat/constants";

import type { TChatHistory, TChatMessage } from "@/models/chat/types";
import type { CSSProperties, MouseEvent } from "react";

const ITEM_HEIGHT = 48;

const Card = ({
  conversation,
  viewConversation,
}: {
  conversation: TChatHistory;
  viewConversation: (conversationId: string) => void;
}) => {
  const queryClient = useQueryClient();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteMutation = useMutation({
    mutationFn: Chat.deleteConversation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["chat-history"] });
    },
  });

  const isUser = conversation.firstChatItem.role === CHAT_ROLE.USER;

  return (
    <Grid
      key={conversation.conversationId}
      component={motion.div}
      layout
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.2 }}
      size={{
        xs: 12,
        md: 6,
        lg: 4,
        xl: 3,
      }}
      style={{
        opacity: deleteMutation.isPending ? 0.5 : 1,
        display: "flex",
        alignItems: "stretch",
        justifyContent: "stretch",
        backgroundColor: isUser ? "#fff" : "transparent",
        boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.1)",
        borderRadius: "1rem",
        ...(theme.applyStyles("dark", {
          backgroundColor: isUser ? "#333" : "transparent",
        }) as CSSProperties),
      }}
    >
      <button
        style={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "stretch",
          flexGrow: 1,
          width: "100%",
          padding: "0.35rem 0.5rem",
          textAlign: "left",
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
        onClick={() => {
          viewConversation(conversation.conversationId);
        }}
      >
        <Message
          key={conversation.firstChatItem.chatId}
          message={conversation.firstChatItem}
        />
      </button>
      <div>
        <IconButton
          aria-label="more"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          size="small"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            },
          }}
        >
          <MenuItem
            dense
            sx={{
              color: "red",
              "&:hover": {
                backgroundColor: "rgba(255, 0, 0, 0.1)", // Light red background on hover
              },
            }}
            onClick={() => {
              deleteMutation.mutate(conversation.conversationId);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </div>
    </Grid>
  );
};

const Message = ({ message }: { message: TChatMessage }) => {
  const theme = useTheme();
  const isUser = message.role === CHAT_ROLE.USER;

  const wrapperStyle = {
    flexGrow: 1,
    padding: isUser ? "0.75rem 1rem" : "0.55rem",
  };

  const buttonStyle = {
    display: "block",
    width: "100%",
    fontSize: "1rem",
    ...(theme.applyStyles("dark", {
      color: "#fff",
    }) as CSSProperties),
  };

  return (
    <div style={wrapperStyle} key={message.chatId}>
      <div style={buttonStyle}>
        <div
          style={{ marginBottom: "0.25rem", fontSize: "0.8rem", opacity: 0.75 }}
        >
          Started: {format(message.createdAt, "MMMM dd yyyy, k:mm")}
        </div>
        {message.content}
      </div>
    </div>
  );
};

export const History = ({
  chatHistory,
  viewConversation,
}: {
  chatHistory: TChatHistory[];
  viewConversation: (conversationId: string) => void;
}) => {
  return (
    <Box sx={{ p: 1, pb: 0 }}>
      <Typography sx={{ pl: 2 }} variant="overline">
        History
      </Typography>

      <Grid container spacing={2}>
        <AnimatePresence>
          {chatHistory.map((conversation) => {
            return (
              <Card
                key={conversation.conversationId}
                conversation={conversation}
                viewConversation={viewConversation}
              />
            );
          })}
        </AnimatePresence>
      </Grid>
    </Box>
  );
};
