export const chatModels = [
  "gpt-4o",
  "o1",
  "claude-3-5-sonnet-latest",
  "claude-3-5-haiku-latest",
] as const;

export const CHAT_ROLE = {
  USER: "user",
  ASSISTANT: "assistant",
} as const;
