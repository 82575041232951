import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useRef, useState } from "react";
import useWebSocket from "react-use-websocket";

import { useSelectedModel } from "@/context/SelectedModelContext";
import Chat from "@/models/chat";
import { CHAT_ROLE } from "@/models/chat/constants";

import { WS_URL } from "./constants";
import { itemIsChatErrorMessage } from "./helpers";

import type { TChatMessage } from "@/models/chat/types";

export const useMason = (
  newMessageCallback: (conversationId: string) => void,
) => {
  const queryClient = useQueryClient();
  const { selectedModel } = useSelectedModel();
  const tempNewChat = useRef<string>("");
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const saveToCache = useCallback(
    (conversationId: string, message: TChatMessage) => {
      queryClient.setQueryData(
        Chat.conversation.queryOptions(conversationId)["queryKey"],
        (oldData) => {
          return [...(oldData ?? []), message];
        },
      );
    },
    [queryClient],
  );

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(WS_URL, {
    share: true,
    shouldReconnect: () => true,
    queryParams: {
      Auth: sessionStorage.getItem("it"),
    },
    onError() {
      setError("An error occurred while sending your message.");
      setWaitingForResponse(false);
    },
    onMessage(event) {
      const data = JSON.parse(event.data);

      if (itemIsChatErrorMessage(data)) {
        setError(data.error.message);
        setWaitingForResponse(false);
        return;
      } else {
        setError(null);
        setWaitingForResponse(false);
      }

      if (tempNewChat.current) {
        newMessageCallback(data.conversationId);
        tempNewChat.current = "";
      }

      saveToCache(data.conversationId, {
        ...data,
        createdAt: data.createdAt ?? new Date().toISOString(),
        chatId: data.chatId ?? crypto.randomUUID(),
      });
    },
  });

  const sendMessage = useCallback(
    (conversationId: string, message: string) => {
      if (message.length === 0) {
        return;
      }

      setWaitingForResponse(true);

      sendJsonMessage({
        content: message,
        conversationId,
        modelId: selectedModel,
        route: "chat",
      });

      saveToCache(conversationId, {
        chatId: crypto.randomUUID(),
        content: message,
        conversationId,
        createdAt: new Date().toISOString(),
        role: CHAT_ROLE.USER,
      });
    },
    [selectedModel, sendJsonMessage, saveToCache],
  );

  const sendMessageAsNewChat = useCallback(
    (message) => {
      setWaitingForResponse(true);

      tempNewChat.current = message;

      sendJsonMessage({
        content: message,
        modelId: selectedModel,
        route: "chat",
      });
    },
    [selectedModel, sendJsonMessage],
  );

  return {
    error,
    sendMessage,
    lastJsonMessage,
    sendMessageAsNewChat,
    waitingForResponse,
  };
};
