import { useQuery } from "@tanstack/react-query";

import type { UseQueryOptions } from "@tanstack/react-query";

import Chat from "./";

export function useChatHistory() {
  return useQuery(Chat.history.queryOptions());
}

export function useChatConversation(
  id: string,
  options?: Pick<UseQueryOptions, "enabled">,
) {
  return useQuery(Chat.conversation.queryOptions(id, options));
}
