export const catFacts = [
  "Cats have over 20 different vocalizations, including purring, meowing, and chirping.",
  "A cat's hearing is much more sensitive than humans, capable of hearing sounds up to 64 kHz.",
  "Cats spend approximately 70% of their lives sleeping.",
  "The first cat in space was a French cat named Felicette in 1963.",
  "Cats have backward-facing spines that allow them to be extremely flexible and agile.",
  "A group of cats is called a 'clowder'.",
  "Cats have scent glands on their face, paws, and tail, which they use to mark territory.",
  "Domestic cats share 95.6% of their genetic makeup with tigers.",
  "Cats cannot taste sweetness due to a genetic mutation.",
  "A cat's nose print is unique, similar to a human fingerprint.",
  "Cats have three eyelids: an upper lid, a lower lid, and a third lid called the nictitating membrane.",
  "The oldest known pet cat was found in a 9,500-year-old grave in Cyprus.",
  "Cats can jump up to six times their length in a single bound.",
  "A cat's normal body temperature is between 100.5°F and 102.5°F.",
  "Cats have approximately 130,000 hairs per square inch.",
  "The first cat show was held in London in 1871.",
  "Cats have about 130 vocal sounds, while dogs have about 10.",
  "A cat's cerebral cortex has 300 million neurons, compared to a dog's 160 million.",
  "Cats can't move their jaw sideways, which is why they can't chew large chunks of food.",
  "A female cat is called a 'queen,' and a male cat is called a 'tom.'",
] as const;
