import { useQueryClient } from "@tanstack/react-query";
import { AnimatePresence } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HistoryIcon from "@mui/icons-material/History";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { Drawer, DrawerManager } from "@/components/Drawer";
import { Spinner } from "@/components/Spinner";
import { useChatConversation, useChatHistory } from "@/models/chat/hooks";

import { catFacts } from "./cat-facts";
import { LOADING_TEXTS } from "./constants";
import { itemIsChatErrorMessage, itemIsChatMessage } from "./helpers";
import { History } from "./History";
import { MasonIcon } from "./MasonIcon";
import { Message } from "./Message";
import { MessageBar } from "./MessageBar";
import { Suggestions } from "./Suggestions";
import { useMason } from "./use-mason";

export default DrawerManager.create(function () {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [activeCatFact, setActiveCatFact] = useState(() => {
    return Math.floor(Math.random() * catFacts.length);
  });
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeView, setActiveView] = useState<"chat" | "history">("history");

  const bodyRef = useRef<HTMLDivElement>(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  useEffect(() => {
    if (!bodyRef.current) {
      return;
    }
    const body = bodyRef.current;
    let timeout;

    const handleScroll = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const { scrollTop, scrollHeight, clientHeight } = body;
        setIsScrolledToBottom(scrollTop + clientHeight >= scrollHeight - 30);
      }, 200);
    };

    body.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      clearTimeout(timeout);
      body.removeEventListener("scroll", handleScroll);
    };
  }, [bodyRef]);

  const {
    error,
    sendMessage,
    lastJsonMessage,
    sendMessageAsNewChat,
    waitingForResponse,
  } = useMason((conversationId) => {
    setSelectedConversationId(conversationId);
  });

  const scrollToTopOfLastMessage = useCallback(() => {
    if (!bodyRef.current) {
      return;
    }

    const lastMessage = bodyRef.current.querySelector(
      "[data-chat-id]:last-child",
    );

    if (lastMessage) {
      lastMessage.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  const scrollToBottom = useCallback(() => {
    if (!bodyRef.current) {
      return;
    }
    setIsScrolledToBottom(true);
    bodyRef.current.scrollTo({
      top: bodyRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [bodyRef]);

  useEffect(() => {
    if (
      itemIsChatMessage(lastJsonMessage) &&
      lastJsonMessage.conversationId === selectedConversationId
    ) {
      setTimeout(scrollToBottom, 150);
      return;
    }

    if (itemIsChatErrorMessage(lastJsonMessage)) {
      setTimeout(scrollToBottom, 150);
      return;
    }
  }, [lastJsonMessage, scrollToBottom, selectedConversationId]);

  const chatHistory = useChatHistory();
  const conversation = useChatConversation(selectedConversationId, {
    enabled: !!selectedConversationId,
  });

  const SHOW_HISTORY =
    activeView === "history" && !selectedConversationId && chatHistory.data;
  const SHOW_NEW_CHAT = activeView === "chat" && !selectedConversationId;
  const SHOW_CHAT = Boolean(selectedConversationId && conversation.data);

  return (
    <Drawer
      bodyRef={bodyRef}
      sheetClassName="mason"
      loading={chatHistory.isLoading || conversation.isLoading}
      title={
        <>
          <Tooltip
            arrow
            title={catFacts[activeCatFact]}
            onClose={() => {
              let next = activeCatFact + 1;
              if (next >= catFacts.length) {
                next = 0;
              }
              setActiveCatFact(next);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexShrink: 0,
                marginRight: "0.35rem",
              }}
            >
              <MasonIcon />
            </div>
          </Tooltip>
          <span className="mobile-hidden inline">Mason</span>
          <span
            className="mobile-hidden inline"
            style={{ marginLeft: "0.25rem", opacity: 0.5, fontWeight: 400 }}
          >
            (alpha)
          </span>
        </>
      }
      toolbarChildren={
        <>
          <Button
            onClick={() => {
              setSelectedConversationId(null);
              setActiveView("chat");
            }}
            startIcon={<AddCircleOutlineIcon />}
            variant="text"
          >
            New Chat
          </Button>{" "}
          <Button
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ["chat-history"] });
              setSelectedConversationId(null);
              setActiveView("history");
            }}
            startIcon={<HistoryIcon />}
            variant="text"
          >
            History
          </Button>
        </>
      }
    >
      {chatHistory.isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1.75,
            p: 2,
            pl: 2.75,
            pb: 0,
          }}
        >
          <Spinner loading={true} /> Loading chat history...
        </Box>
      ) : null}

      {conversation.isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1.5,
            p: 2,
            pb: 0,
          }}
        >
          <Spinner loading={true} /> Loading chat...
        </Box>
      ) : null}

      {SHOW_CHAT ? (
        <Box sx={{ pt: 1 }}>
          <AnimatePresence initial={false} mode="popLayout">
            {conversation.data.map((message) => {
              return <Message key={message.chatId} message={message} />;
            })}
          </AnimatePresence>
        </Box>
      ) : null}

      {SHOW_CHAT || SHOW_NEW_CHAT ? (
        <Box sx={{ pt: 1 }}>
          {waitingForResponse ? (
            <Message
              isLoadingMessage={true}
              message={{
                createdAt: new Date().toISOString(),
                conversationId: "temp",
                chatId: "temp-loading",
                role: "assistant",
                content:
                  LOADING_TEXTS[
                    Math.floor(Math.random() * LOADING_TEXTS.length)
                  ],
                userUUID: "temp",
                modelId: "gpt-4o",
              }}
            />
          ) : null}
          {error ? (
            <Message
              isErrorMessage={true}
              message={{
                createdAt: new Date().toISOString(),
                conversationId: "temp",
                chatId: "temp-error",
                role: "assistant",
                content: error ?? "ERROR",
                userUUID: "temp",
                modelId: "gpt-4o",
              }}
            />
          ) : null}
        </Box>
      ) : null}

      {(showSuggestions && SHOW_CHAT) || SHOW_NEW_CHAT ? (
        <Suggestions
          sendMessage={(message) => {
            sendMessage(selectedConversationId, message);
            setShowSuggestions(false);
          }}
        />
      ) : null}

      {SHOW_CHAT || SHOW_NEW_CHAT ? (
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            p: 2,
            pt: SHOW_NEW_CHAT ? 2 : 0,
            backgroundColor: "#f3f3f3",
            ...theme.applyStyles("dark", {
              backgroundColor: "#111",
            }),
          }}
        >
          <MessageBar
            isScrolledToBottom={isScrolledToBottom}
            showModelSelectorTip={SHOW_NEW_CHAT}
            toggleSuggestions={() => {
              setShowSuggestions(!showSuggestions);
            }}
            scrollToBottom={scrollToBottom}
            scrollToTopOfLastMessage={scrollToTopOfLastMessage}
            sendMessage={
              SHOW_CHAT
                ? (message: string) => {
                    sendMessage(selectedConversationId, message);
                    setTimeout(scrollToBottom, 150);
                  }
                : (message: string) => {
                    sendMessageAsNewChat(message);
                  }
            }
            waitingForResponse={waitingForResponse}
          />
        </Box>
      ) : null}

      {SHOW_HISTORY ? (
        <>
          <Box
            sx={{
              position: "relative",
              mb: 3,
              p: 2,
              pb: 0,
              height: chatHistory.data.length ? "auto" : "100%",
            }}
          >
            <div
              style={{
                position: chatHistory.data.length ? "static" : "absolute",
                top: "50%",
                left: "10%",
                right: "10%",
                transform: chatHistory.data.length ? "" : "translateY(-50%)",
              }}
            >
              {!chatHistory.data.length ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className={waitingForResponse ? "animation-bounce" : ""}>
                    <MasonIcon size={75} />
                  </div>
                  <Typography sx={{ mb: 1.5 }} variant="body1">
                    Hello, I&apos;m Mason. What can I help you with today?
                  </Typography>
                </div>
              ) : null}
              <Typography sx={{ pl: 0.5 }} variant="overline">
                New Chat
              </Typography>
              <div
                style={{
                  width: "100%",
                }}
              >
                <MessageBar
                  isScrolledToBottom={true}
                  toggleSuggestions={() => {
                    setShowSuggestions(!showSuggestions);
                  }}
                  scrollToBottom={scrollToBottom}
                  scrollToTopOfLastMessage={scrollToTopOfLastMessage}
                  sendMessage={
                    SHOW_CHAT
                      ? (message: string) => {
                          sendMessage(selectedConversationId, message);
                          setTimeout(scrollToBottom, 150);
                        }
                      : (message: string) => {
                          sendMessageAsNewChat(message);
                        }
                  }
                  waitingForResponse={waitingForResponse}
                />
              </div>
            </div>
          </Box>
          <History
            chatHistory={chatHistory.data}
            viewConversation={setSelectedConversationId}
          />
          <br />
        </>
      ) : null}
    </Drawer>
  );
});
