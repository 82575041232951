import { type UseQueryOptions, queryOptions } from "@tanstack/react-query";

import routes from "./routes";

import type { TChatHistory, TChatMessage } from "./types";

/* Finders
/* ------------------------------------------------------------------ */

const history = {
  queryOptions() {
    return queryOptions({
      queryKey: ["chat-history"] as const,
      queryFn: async function () {
        const url = routes.history();
        return await window.auth.client.get(url).json<TChatHistory[]>();
      },
    });
  },
};

const conversation = {
  queryOptions(id: string, options: Pick<UseQueryOptions, "enabled"> = {}) {
    return queryOptions<TChatMessage[], Error>({
      queryKey: ["chat-conversation", id] as const,
      queryFn: async function () {
        const url = routes.conversation(id);
        return await window.auth.client.get(url).json();
      },
      ...options,
    });
  },
};

/* Actions
/* ------------------------------------------------------------------ */
async function deleteConversation(id: string) {
  const url = routes.delete(id);
  return await window.auth.client.delete(url).json();
}

export default {
  conversation,
  deleteConversation,
  history,
};
