import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const suggestions = [
  {
    id: 1,
    Icon: AutoFixHighIcon,
    label: "Create Canvas instances for all published inventory items",
  },
  {
    id: 2,
    Icon: FormatListBulletedIcon,
    label: "List all unpublished inventory items for the next 3 months",
  },
  {
    id: 3,
    Icon: HelpOutlineIcon,
    label: "What are the login rates for last week?",
  },
  {
    id: 4,
    Icon: HelpOutlineIcon,
    label:
      "How many red flagged applications have been submitted in the last 30 days?",
  },
];

export const Suggestions = ({
  sendMessage,
}: {
  sendMessage: (message: string) => void;
}) => {
  const theme = useTheme();
  return null;

  return (
    <>
      <Typography sx={{ pl: 2 }} variant="overline">
        Shortcuts
      </Typography>
      <Grid container spacing={2} sx={{ p: 2, pt: 1 }}>
        {suggestions.map((suggestion) => {
          return (
            <Grid
              size={{ xs: 12, sm: 6 }}
              key={suggestion.id}
              onClick={() => {
                sendMessage(suggestion.label);
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  borderLeft: `3px solid ${theme.palette.primary.main}`,
                }}
              >
                <Box
                  sx={{
                    display: "block",
                    width: "100%",
                    border: "none",
                    bgcolor: "background.paper",
                    p: 1,
                    pl: 2,
                    cursor: "pointer",
                    textAlign: "left",
                    fontSize: "0.85rem",
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: "0.25rem",
                      opacity: 0.5,
                      color: theme.palette.primary.main,
                    }}
                  >
                    <suggestion.Icon fontSize="small" />
                  </Box>
                  &quot;{suggestion.label}&quot;
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
