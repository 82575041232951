import { format } from "date-fns";
import DOMPurify from "dompurify";
import { motion } from "framer-motion";
import { marked } from "marked";

import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

import { CHAT_ROLE } from "@/models/chat/constants";

import { MasonIcon } from "./MasonIcon";

import type { TChatMessage } from "@/models/chat/types";
import type { CSSProperties } from "react";

const UserMessage = ({ message }: { message: TChatMessage }) => {
  const HTML = DOMPurify.sanitize(marked.parse(message.content ?? ""));

  return (
    <Tooltip
      title={format(message.createdAt, "MMMM dd yyyy, k:mm")}
      placement="bottom-end"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
    >
      <div
        style={{
          color: "#fff",
          padding: "0.35rem 1rem",
          backgroundColor: "rgb(145,52,246)",
          background:
            "linear-gradient(180deg, rgba(145,52,246,1) 0%, rgba(125,32,226,1) 100%)",
          borderRadius: "1.25rem",
          boxShadow: "0px 1px 2px 1px rgb(145, 52, 246, 0.2)",
          maxWidth: "80%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "0.5rem",
          }}
        >
          <div
            className="prose"
            dangerouslySetInnerHTML={{
              __html: HTML,
            }}
          />
        </div>
      </div>
    </Tooltip>
  );
};

const AssistantMessage = ({
  message,
  isLoadingMessage = false,
  isErrorMessage = false,
}: {
  message: TChatMessage;
  isLoadingMessage?: boolean;
  isErrorMessage?: boolean;
}) => {
  const theme = useTheme();
  const HTML = DOMPurify.sanitize(marked.parse(message.content));

  return (
    <div
      style={{
        color: isErrorMessage ? "#ff0000" : "inherit",
        padding: "0 0.55rem",
        backgroundColor: "transparent",
        borderRadius: "1.25rem",
        boxShadow: "none",
        maxWidth: "100%",
        ...(theme.applyStyles("dark", {
          backgroundColor: "transparent",
        }) as CSSProperties),
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "0.5rem",
        }}
      >
        <Tooltip
          title={
            message.createdAt && message.modelId ? (
              <div>
                {format(message.createdAt, "MMMM dd yyyy, k:mm")}
                <br />
                {message.modelId}
              </div>
            ) : null
          }
          placement="bottom-start"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -16],
                  },
                },
              ],
            },
          }}
        >
          <div
            className={`${isLoadingMessage ? "animation-bounce" : ""} ${
              isErrorMessage ? "text-red-500" : ""
            }`}
            style={{
              position: "sticky",
              top: "4px",
              flexShrink: 0,
              marginRight: "0.35rem",
            }}
          >
            <MasonIcon
              className={isErrorMessage ? "animation-bounce-head" : ""}
              fill={isErrorMessage ? "#ff0000" : undefined}
            />
          </div>
        </Tooltip>
        <div
          className="prose"
          style={{
            color: isErrorMessage ? "#ff0000" : undefined,
          }}
          dangerouslySetInnerHTML={{
            __html: HTML,
          }}
        />
      </div>
    </div>
  );
};

export const Message = ({
  message,
  isLoadingMessage = false,
  isErrorMessage = false,
}: {
  message: TChatMessage;
  isLoadingMessage?: boolean;
  isErrorMessage?: boolean;
}) => {
  const IS_USER = message.role === CHAT_ROLE.USER;

  return (
    <motion.div
      key={message.chatId}
      layout
      data-chat-id={message.chatId}
      data-chat-role={message.role}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{
        opacity: { duration: 0.2 },
        delay: IS_USER ? 0 : 0.2,
        layout: {
          type: "spring",
          bounce: 0.4,
          duration: 0.5,
        },
      }}
      style={{
        display: "flex",
        justifyContent: IS_USER ? "flex-end" : "flex-start",
        originX: IS_USER ? 1 : 0,
        paddingTop: "1rem",
        paddingBottom: IS_USER ? "1rem" : "1rem",
        paddingLeft: "0.5rem",
        paddingRight: "1rem",
        scrollMarginTop: "30px",
      }}
    >
      {message.role === CHAT_ROLE.USER ? (
        <UserMessage message={message} />
      ) : (
        <AssistantMessage
          message={message}
          isLoadingMessage={isLoadingMessage}
          isErrorMessage={isErrorMessage}
        />
      )}
    </motion.div>
  );
};
