import { createContext, useContext, useState } from "react";

import { chatModels } from "@/models/chat/constants";

import type { TChatModel } from "@/models/chat/types";
import type { PropsWithChildren } from "react";

type TSelectedModelContext = {
  selectedModel: TChatModel;
  setSelectedModel: (model: TChatModel) => void;
};

export const SelectedModelContext = createContext<TSelectedModelContext>({
  selectedModel: chatModels[0],
  setSelectedModel: () => undefined, // no-op default
});

export const useSelectedModel = () => {
  return useContext(SelectedModelContext);
};

export const SelectedModelProvider = function SelectedModelProvider({
  children,
}: PropsWithChildren) {
  const [selectedModel, setSelectedModel] = useState<TChatModel>(chatModels[0]);

  return (
    <SelectedModelContext.Provider value={{ selectedModel, setSelectedModel }}>
      {children}
    </SelectedModelContext.Provider>
  );
};
