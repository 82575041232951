import { remove } from "lodash-es";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

import { ActionButtons } from "@/components/ActionButtons";
import FormErrorMessage from "@/components/FormErrorMessage";
import ErrorMessage from "@/components/HookFormErrorMessage";
import {
  FORM_PAPER_HEADING_SX,
  FORM_PAPER_SX,
  INVENTORY_TYPE_MAP,
  INVENTORY_TYPE_OPTIONS,
} from "@/constants";
import Catalogue from "@/models/catalogue";
import { CatalogueItemType } from "@/models/catalogue/constants";
import { useCatalogueForPartner } from "@/models/catalogue/hooks";

import type { TCatalogueItem } from "@/models/catalogue/types";
import type { TPartner } from "@/models/partner/types";
import type { UseQueryResult } from "@tanstack/react-query";

export default function CatalogueForm({
  cancel,
  data = {},
  isEdit,
  isSaving,
  partners,
  saveError,
  onSubmit,
}: {
  cancel: () => void;
  data: Partial<TCatalogueItem> & { partner_id?: string };
  isEdit?: boolean;
  isSaving?: boolean;
  onSubmit: (values: TCatalogueItem) => void;
  partners: UseQueryResult<TPartner[], Error>;
  saveError?: Error;
}) {
  const [metadata, setMetadata] = useState(
    Object.entries(data.metadata || {}) || [],
  );

  const deleteRow = function (index) {
    remove(metadata, function (value, i) {
      return index === i;
    });

    setMetadata([...metadata]);
  };

  const editRow = function (index, key, value) {
    metadata[index] = [key, value];
    setMetadata([...metadata]);
  };

  const addRow = function () {
    setMetadata([...metadata, ["", ""]]);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      ...Catalogue.propertiesOrDefaults(data),
      partner_id: data.partner_id || "",
    },
  });

  const partnerId = watch("partner_id");
  const itemType = watch("type");

  const catalogueItems = useCatalogueForPartner(partnerId);

  const handleScopeChange = (selected) => {
    setValue("scopedPrograms", selected);
  };

  const preSubmit = function (formValues) {
    const _id = formValues.partner_id;
    delete formValues.partner_id;
    const name = partners.data.find((p) => p._id === _id)["name"];

    onSubmit({
      ...formValues,
      metadata: Object.fromEntries(metadata),
      partner: {
        _id,
        name,
      },
    });
  };

  const filteredCatalogue = (catalogueItems?.data ?? []).filter(
    (item) => item.type !== CatalogueItemType.AddOn,
  );

  return (
    <form onSubmit={handleSubmit(preSubmit)} noValidate>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <Grid container spacing={1}>
          <Grid size={12}>
            {isEdit && (
              <div>
                {data.partner.name}
                <Controller
                  control={control}
                  name="partner_id"
                  render={({ field: { ref, ...rest } }) => {
                    return <input ref={ref} {...rest} type="hidden" />;
                  }}
                />
              </div>
            )}
            {!isEdit && (
              <>
                {partners.isLoading && (
                  <div>
                    <CircularProgress size="1.5rem" sx={{ mr: 2 }} /> Loading
                    Partners...
                  </div>
                )}
                {partners.data && (
                  <FormControl
                    error={errors.partner_id ? true : false}
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel id="partner-select-field-label">
                      Partner
                    </InputLabel>
                    <Controller
                      control={control}
                      name="partner_id"
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Partner"
                          labelId="partner-select-field-label"
                        >
                          {partners.data.map((partner) => {
                            return (
                              <MenuItem key={partner._id} value={partner._id}>
                                {partner.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    <ErrorMessage errors={errors} name="partner_id" />
                  </FormControl>
                )}
              </>
            )}
          </Grid>
          {data._id ? (
            <Grid size={12}>Type: {INVENTORY_TYPE_MAP[data.type]}</Grid>
          ) : (
            <Grid size={12}>
              <FormControl
                error={errors.type ? true : false}
                fullWidth
                margin="normal"
              >
                <InputLabel id="type-select-field-label">Type</InputLabel>
                <Controller
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Type"
                      labelId="type-select-field-label"
                    >
                      {INVENTORY_TYPE_OPTIONS.map((item) => {
                        return (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  rules={{ required: "This field is required" }}
                />
                <ErrorMessage errors={errors} name="type" />
              </FormControl>
            </Grid>
          )}
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                control={control}
                name="name"
                defaultValue=""
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      error={errors.name ? true : false}
                      inputRef={ref}
                      label="Name"
                      required
                      variant="outlined"
                      {...rest}
                    />
                  );
                }}
                rules={{ required: "This field is required" }}
              />
              <ErrorMessage errors={errors} name="name" />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                control={control}
                name="internalName"
                defaultValue=""
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      error={errors.internalName ? true : false}
                      inputRef={ref}
                      label="Internal Name"
                      required
                      variant="outlined"
                      {...rest}
                    />
                  );
                }}
                rules={{ required: "This field is required" }}
              />
              <ErrorMessage errors={errors} name="internalName" />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                control={control}
                name="endOfCourseSurveyURL"
                defaultValue=""
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      error={errors.endOfCourseSurveyURL ? true : false}
                      inputRef={ref}
                      label="End of Course Survey URL"
                      variant="outlined"
                      {...rest}
                    />
                  );
                }}
              />
              <ErrorMessage errors={errors} name="endOfCourseSurveyURL" />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                control={control}
                name="msrp"
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <TextField
                      error={errors.msrp ? true : false}
                      inputRef={ref}
                      label="MSRP"
                      required
                      variant="outlined"
                      {...rest}
                    />
                  );
                }}
                rules={{ required: "This field is required" }}
              />
              <ErrorMessage errors={errors} name="msrp" />
              <FormHelperText>USD</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      {itemType === CatalogueItemType.AddOn && catalogueItems.data ? (
        <>
          <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
            Qualifying Products
          </Typography>
          <Paper
            elevation={1}
            sx={FORM_PAPER_SX}
            className="animation-fade-in-slow"
          >
            <Grid size={12}>
              <FormControl
                error={errors.scopedPrograms ? true : false}
                fullWidth
                margin="normal"
              >
                <InputLabel id="catalogue-program-scope-select-field-label">
                  Scoped Programs*
                </InputLabel>
                <Controller
                  control={control}
                  name="scopedPrograms"
                  render={({ field }) => {
                    const { value, ...rest } = field;
                    return (
                      <Select
                        {...rest}
                        value={value ?? []}
                        multiple
                        onChange={(event) => {
                          handleScopeChange(event.target.value);
                        }}
                        labelId="catalogue-program-scope-select-field-label"
                        renderValue={(selected) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((programId) => (
                                <Chip
                                  key={programId}
                                  label={
                                    filteredCatalogue.find(
                                      (p) => p._id === programId,
                                    )["name"]
                                  }
                                />
                              ))}
                            </Box>
                          );
                        }}
                      >
                        {filteredCatalogue.map((item) => {
                          return (
                            <MenuItem dense key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    );
                  }}
                  rules={{ required: "This field is required" }}
                />
                <ErrorMessage errors={errors} name="scopedPrograms" />
              </FormControl>
            </Grid>
          </Paper>
        </>
      ) : null}

      <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
        Status
      </Typography>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <FormControl fullWidth>
          <Controller
            name="published"
            control={control}
            defaultValue={data.published}
            render={({ field: { onChange, value, name, ref } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    color="primary"
                    inputRef={ref}
                    name={name}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                label="Published"
                labelPlacement="end"
              />
            )}
          />
          <FormHelperText>
            When published and the dates have not passed, this item will appear
            on the marketing sites and application form
          </FormHelperText>
        </FormControl>
      </Paper>
      <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
        Metadata
      </Typography>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <Button
          variant="outlined"
          startIcon={<AddCircleIcon />}
          onClick={addRow}
        >
          Add Data
        </Button>
        {Boolean(metadata.length) && (
          <>
            <br />
            <br />
          </>
        )}
        {metadata.map(function ([k, v], index) {
          return (
            <Grid sx={{ marginBottom: 1 }} key={index} container spacing={1}>
              <Grid size={{ xs: 12, sm: 5 }}>
                <TextField
                  fullWidth
                  label="Key"
                  onChange={(e) => {
                    editRow(index, e.target.value, v);
                  }}
                  required
                  value={k}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 5 }}>
                <TextField
                  fullWidth
                  label="Value"
                  onChange={(e) => {
                    editRow(index, k, e.target.value);
                  }}
                  value={v}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 2 }}>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    deleteRow(index);
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </Paper>
      <br />
      <ActionButtons cancel={cancel} isSaving={isSaving}>
        <FormErrorMessage gutterBottom error={saveError} />
      </ActionButtons>
    </form>
  );
}
