export const WS_URL = `wss://ws-dev-galaxy.precollegeprograms.org/chat`;

export const LOADING_TEXTS = [
  "Thinking deep thoughts...",
  "Consulting my digital crystal ball...",
  "Pondering the mysteries of the universe...",
  "Doing some quantum computing...",
  "Checking my AI handbook...",
  "Processing in binary...",
  "Gathering artificial wisdom...",
  "Running advanced algorithms...",
  "Searching through my neural networks...",
];
