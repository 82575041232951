import { useTheme } from "@mui/material/styles";

import { useSelectedModel } from "@/context/SelectedModelContext";
import { chatModels } from "@/models/chat/constants";

import type { TChatModel } from "@/models/chat/types";
import type { CSSProperties } from "react";

export const ModelSelector = () => {
  const theme = useTheme();
  const { selectedModel, setSelectedModel } = useSelectedModel();

  return (
    <select
      value={selectedModel}
      onChange={(event) => {
        setSelectedModel(event.target.value as TChatModel);
      }}
      style={{
        padding: "0 4px",
        border: "none",
        backgroundColor: "transparent",
        ...(theme.applyStyles("dark", {
          color: "#fff",
        }) as CSSProperties),
      }}
    >
      {chatModels.map((model) => {
        return <option key={model}>{model}</option>;
      })}
    </select>
  );
};
