import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

//import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TelegramIcon from "@mui/icons-material/Telegram";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

import { Spinner } from "@/components/Spinner";

import { MasonIcon } from "./MasonIcon";
import { ModelSelector } from "./ModelSelector";

export const MessageBar = function MessageBar({
  isScrolledToBottom,
  scrollToBottom,
  scrollToTopOfLastMessage,
  sendMessage,
  showModelSelectorTip = false,
  toggleSuggestions,
  waitingForResponse,
}: {
  isScrolledToBottom: boolean;
  scrollToBottom: () => void;
  scrollToTopOfLastMessage: () => void;
  sendMessage: (message: string) => void;
  showModelSelectorTip?: boolean;
  toggleSuggestions: () => void;
  waitingForResponse: boolean;
}) {
  const theme = useTheme();
  const [input, setInput] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  useEffect(() => {
    scrollToTopOfLastMessage();
  }, []);

  return (
    <Box
      sx={{
        position: "sticky",
        width: "100%",
        bottom: 0,
        boxShadow:
          "0 3px 10px 0 rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        p: 2,
        paddingBottom: 0.5,
        zIndex: 2,
        backgroundColor: "#fff",
        ...theme.applyStyles("dark", {
          backgroundColor: "#222",
        }),
      }}
    >
      <AnimatePresence>
        {!isScrolledToBottom ? (
          <motion.button
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{
              type: "spring",
              stiffness: 800,
              damping: 25,
            }}
            onClick={scrollToBottom}
            style={{
              position: "absolute",
              top: "-42px",
              right: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "32px",
              width: "32px",
              border: "#fff",
              borderRadius: "50%",
              color: "#333",
              cursor: "pointer",
              backgroundColor: "#fff",
              zIndex: 2000,
              boxShadow:
                "0 4px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 4px 0 rgba(0, 0, 0, 0.2)",
            }}
            aria-label="Back to bottom"
          >
            <ExpandMoreIcon />
          </motion.button>
        ) : null}
      </AnimatePresence>
      <Stack
        component="form"
        direction="row"
        spacing={1}
        sx={{
          mb: 0.75,
        }}
        onSubmit={(e) => {
          e.preventDefault();
          if (waitingForResponse) {
            return;
          }
          sendMessage(input);
          setInput("");
        }}
      >
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <OutlinedInput
            type="text"
            name="prompt"
            value={input}
            onChange={handleInputChange}
            size="small"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end" type="submit">
                  {waitingForResponse ? (
                    <Spinner loading={true} />
                  ) : (
                    <TelegramIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Stack>

      <Stack direction="row" spacing={1}>
        {/* <IconButton
          aria-label="suggestions"
          size="small"
          onClick={() => {
            toggleSuggestions();
            setTimeout(() => {
              scrollToBottom();
            }, 100);
          }}
        >
          <ChatOutlinedIcon fontSize="small" />
        </IconButton> */}
        <Tooltip
          arrow
          open={showModelSelectorTip}
          title={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <MasonIcon fill="rgba(255,255,255, 0.75)" size={24} /> Trying
              changing the model here
            </div>
          }
          placement="bottom-start"
        >
          <div style={{ opacity: 0.6 }}>
            <ModelSelector />
          </div>
        </Tooltip>
      </Stack>
    </Box>
  );
};
